<template>
  <div>
    <h2>Feedback</h2>

    <el-divider />

    <div v-if="myPreviousOrders.length > 0">
      <h3>Please give us feedback for your previous order</h3>

      <div v-for="myPreviousOrder in myPreviousOrders" :key="myPreviousOrder.id">
        <p>Order number: {{ myPreviousOrder.id }}</p>

        <el-table :data="myPreviousOrder.items">
          <el-table-column>
            <template #default="scope">
              <el-image
                v-if="scope.row.imageUrl"
                :src="scope.row.imageUrl"
                style="width: 150px; height: 150px"
              />
            </template>
          </el-table-column>

          <el-table-column prop="name" label="Name" />

          <el-table-column label="Rate" min-width="125">
            <template #default="scope">
              <el-rate v-model="scope.row.rate" />
            </template>
          </el-table-column>

          <el-table-column label="Feedback">
            <template #default="scope">
              <el-input
                v-model="scope.row.content"
                :autosize="{ minRows: 2 }"
                maxlength="1000"
                show-word-limit
                type="textarea"
                placeholder="Please leave your feedback here"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-button 
        type="primary" 
        @click="submitFeedback" 
        :loading="loading"
        size="large" 
        style="margin-top: 20px"
      >
        Submit feedback
      </el-button>
    </div>

    <!-- <el-divider /> -->

    <!-- <div>
      <h1>Order Confirmation</h1>

      <el-table :data="orderItems">
        <el-table-column>
          <template #default="scope">
            <el-image
              v-if="scope.row.item.imageUrl"
              :src="scope.row.item.imageUrl"
              style="width: 150px; height: 150px"
            ></el-image>
          </template>
        </el-table-column>

        <el-table-column prop="item.name" label="Name" />

        <el-table-column label="Unit price">
          <template #default="scope">
            {{ `$ ${scope.row.item.price}` }}
          </template>
        </el-table-column>

        <el-table-column prop="quantity" label="Quantity" />

        <el-table-column label="Total price">
          <template #default="scope">
            {{ `$ ${scope.row.item.price * scope.row.quantity}` }}
          </template>
        </el-table-column>
      </el-table>

      <el-button 
        type="primary" 
        @click="placeOrder" 
        :loading="loading"
        size="large" 
        style="margin-top: 20px"
      >
        Place Order ($ {{ totalPrice }})
      </el-button>
    </div> -->
  </div>
</template>

<script>
import OrderService from '../services/OrderService'
import MenuService from '../services/MenuService'
import ItemFeedbackService from "../services/ItemFeedbackService"
import utils from '@/helper/utils.js'

export default {
  data() {
    return {
      loading: false,
      orderItems: this.$store.state.cart.orderItems,
      myPreviousOrders: [],
      mostRecentMenu: null
    }
  },
  computed: {
    totalPrice() {
      return this.$store.state.cart.orderItems.reduce((total, oi) => total + oi.quantity * oi.item.price, 0);
    },
  },
  methods: {
    async submitFeedback() {
      this.loading = true;

      try {
        // Send item feedbacks
        const itemFeedbacks = []
        for (const myPreviousOrder of this.myPreviousOrders) {
          for (const item of myPreviousOrder.items) {
            if (item.rate === 0 && !item.content) {
              continue
            }

            itemFeedbacks.push({
              itemId: item.id,
              rate: item.rate,
              content: item.content 
            })
          }
        }

        await ItemFeedbackService.bulkCreate({
          menuDate: this.mostRecentMenu.date, 
          itemFeedbacks
        })
      } catch (e) {
        utils.handleHttpError(e, this)
        return
      } finally {
        this.loading = false;
      }
      
      this.$router.push({ name: 'MyOrderList' })

      try {
        await this.$alert('Thank you for the feedback!', 'Success')
      } catch (e) {
        return
      }
    },
    async retrieveMyPreviousOrders() {
      try {
        const resp = await OrderService.getMyOrdersByMenuDate(this.mostRecentMenu.date)
        this.myPreviousOrders = resp.data
        console.log("myPreviousOrders: ", this.myPreviousOrders)
      } catch (e) {
        utils.handleHttpError(e, this)
      }
    },
    async retrieveMostRecentMenu() {
      try {
        const resp = await MenuService.getMostRecentMenu()
        this.mostRecentMenu = resp.data
        console.log("mostRecentMenu: ", this.mostRecentMenu)
      } catch (e) {
        utils.handleHttpError(e, this)
      }
    }
  },
  async mounted() {
    await this.retrieveMostRecentMenu()
    await this.retrieveMyPreviousOrders()
  }
}
</script>

<style>
</style>